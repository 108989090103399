import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getClubOption } from '@aaa/emember/store-membership';
import { AsyncPipe } from '@angular/common';
import { AppStore } from '@aaa/emember/store-types';

@Component({
  selector: 'ava-alert-critical-error',
  template: `
    <div style="font-size: 24px; font-weight: 400; text-align: center">
      We are experiencing technical difficulties. Please try again later or call member services at

      @if (clubOption$ | async; as clubOption) {
        <a class="text-sky-600 underline dark:text-sky-500 hover:no-underline" href="tel:{{ clubOption.phoneNumber }}">{{ clubOption.phoneNumberView }} </a>
      }

      for immediate support.
    </div>
  `,
  standalone: true,
  imports: [AsyncPipe],
})
export class AlertCriticalErrorComponent {
  store = inject(Store<AppStore>);
  clubOption$ = this.store.select(getClubOption);
}
