import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormBaseControlComponent } from './base';

@Component({
  selector: 'ava-form-radio',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, ReactiveFormsModule],
  standalone: true,
  template: `
    <fieldset>
      <legend class="-md:flex-col m-0 flex gap-4">
        <span class="text-base font-extralight">{{ formLabel }}</span>
        <div class="flex gap-2">
          @for (option of options; track option) {
            <p class="flex items-center gap-1">
              <input
                class="peer hidden"
                type="radio"
                [formControl]="formControl"
                [value]="option[valueKey]"
                [attr.id]="option[valueKey]"
              />
              <span
                class="peer-checked:after:bg-blue relative cursor-pointer before:visible before:absolute before:-my-2 before:inline-block before:h-4 before:w-4 before:rounded-full before:border-[1px] before:border-blue-900 before:bg-white before:content-[''] after:visible after:absolute after:-mt-1 after:ml-1 after:h-2 after:w-2 after:rounded-full after:bg-white after:content-['']"
              ></span>
              <label
                (keydown.space)="formControl.setValue(option[valueKey])"
                (keydown.enter)="formControl.setValue(option[valueKey])"
                [attr.for]="option[valueKey]"
                class="focus-visible:outline-blue-light mx-4 mb-0 cursor-pointer text-base font-normal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                tabindex="0"
              >
                {{ option[displayLabel] }}
              </label>
            </p>
          }
        </div>
      </legend>
    </fieldset>
  `,
})
export class FormRadioComponent extends FormBaseControlComponent {
  @Input() displayLabel = 'label';
  @Input() valueKey = 'value';
  @Input() options = [];
}
