import { Component, forwardRef, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import * as SmartySDK from 'smartystreets-javascript-sdk';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { BehaviorSubject, debounceTime, from, map, of, Subject, switchMap, takeUntil } from 'rxjs';
import { RequestStatus } from '../../../../../types/request-status';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { SmartStreetsClient, smartyStreetsProvider } from '../../providers/smarty.provider';

const Lookup = SmartySDK.usAutocompletePro.Lookup;

interface Address {
  streetLine: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  entries: number;
}

type ControlAddressState = {
  zipcode: string;
  state: string;
  city: string;
};

@Component({
  selector: 'ava-form-smarty-address',
  template: `
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControlStatus" [nzErrorTip]="errors" nzHasFeedback>
        <nz-input-group>
          <input
            type="text"
            nz-input
            [nzAutocomplete]="auto"
            [name]="formControlName"
            [formControl]="addressFormControl"
            [placeholder]="placeholder"
            class="ava-form-control"
          />
          <nz-autocomplete nzOverlayClassName="ava-address-autocomplete" #auto>
            @for (address of addresses$ | async; track address) {
              <nz-auto-option [nzValue]="address.streetLine" (click)="selectAddress(address)">
                {{ address.streetLine }}, {{ address.city }}, {{ address.state }} {{ address.zipcode }}
              </nz-auto-option>
            }
          </nz-autocomplete>
        </nz-input-group>

        <ng-template #errors let-control>
          @if ((addressFormControl?.errors | keyvalue)[0]; as error) {
            <div
              class="ava-form-error"
              [innerHTML]="errorMessages && errorMessages[error.key] ? errorMessages[error.key] : error.key"
            ></div>
          }
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      @import '../../../../styles/ava-responsive';

      :host {
        input {
          font-weight: 200;
          font-family: var(--ava-font-family);
        }

        input::placeholder,
        ::ng-deep .ant-input-suffix {
          @include formControlPlaceholderStyle();
        }

        .ava-form-control {
          @include formControlInputFontStyle();
        }
      }

      nz-form-item.ant-form-item:not(.ant-form-item-with-help) {
        margin-bottom: 0;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmartyAddressComponent),
      multi: true,
    },
    smartyStreetsProvider(),
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    ReactiveFormsModule,
    NzAutocompleteModule,
  ],
  standalone: true,
})
export class SmartyAddressComponent implements ControlValueAccessor, OnInit, OnDestroy {
  container = inject(ControlContainer);
  smartStreetClient = inject(SmartStreetsClient);
  readonly RequestStatus = RequestStatus;
  @Input() includeOnlyStates = [];
  @Input() formControlName = '';
  @Input() zipcodeFormControlName = '';
  @Input() stateFormControlName = '';
  @Input() cityFormControlName = '';
  @Input() placeholder = '';
  @Input() errorMessages: { [key: string]: string } = {};

  prevState: ControlAddressState = { zipcode: '', state: '', city: '' };

  effect$ = new BehaviorSubject<string>('');
  addresses$ = new BehaviorSubject<Address[]>([]);
  requestStatus$ = new BehaviorSubject<RequestStatus>(RequestStatus.UNSENT);

  alive$ = new Subject();

  addressFormControl = new FormControl('', { nonNullable: true, validators: [Validators.required] });

  get parentAddressFormControl() {
    return this.container.control?.get(this.formControlName) as FormControl;
  }

  get zipcodeFormControl() {
    return this.container.control?.get(this.zipcodeFormControlName) as FormControl | null;
  }

  get stateFormControl() {
    return this.container.control?.get(this.stateFormControlName) as FormControl | null;
  }

  get cityFormControl() {
    return this.container.control?.get(this.cityFormControlName) as FormControl | null;
  }

  writeValue(obj: any): void {
    this.addressFormControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    // throw new Error("Method not implemented.")
  }

  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.")
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.addressFormControl.disable({ onlySelf: true, emitEvent: false });
    } else {
      this.addressFormControl.enable({ onlySelf: true, emitEvent: false });
    }
  }

  selectAddress(address: Address) {
    this.setPrevState({
      zipcode: address.zipcode,
      state: address.state,
      city: address.city,
    });
  }

  setPrevState(state: ControlAddressState): void {
    if (state.zipcode && this.zipcodeFormControl) {
      this.zipcodeFormControl.setValue(state.zipcode);
    }

    if (state.state && this.stateFormControl) {
      this.stateFormControl.setValue(state.state);
    }

    if (state.city && this.cityFormControl) {
      this.cityFormControl.setValue(state.city);
    }
  }

  get formControlStatus() {
    if (this.addressFormControl?.touched) {
      switch (this.addressFormControl.status) {
        case 'PENDING':
          return 'validating';
        case 'INVALID':
          return 'error';
        case 'VALID':
          return 'success';
        case 'DISABLED':
          return '';
      }
    }

    return 'VALID';
  }

  ngOnInit() {
    if (this.addressFormControl) {
      this.addressFormControl.valueChanges.subscribe((address) => {
        this.effect$.next(address as string);
      });
    }

    if (this.parentAddressFormControl && this.addressFormControl) {
      this.addressFormControl.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((address) => this.parentAddressFormControl.setValue(address));

      this.parentAddressFormControl.statusChanges.subscribe(() => {
        if (this.parentAddressFormControl.touched !== this.addressFormControl.touched) {
          if (this.parentAddressFormControl.touched) {
            this.addressFormControl.markAsTouched({ onlySelf: true });
          } else {
            this.addressFormControl.markAsTouched({ onlySelf: true });
          }
        }
      });
    }

    this.effect$
      .pipe(
        tap(() => this.requestStatus$.next(RequestStatus.RUNNING)),
        debounceTime(200),
        switchMap((address) => {
          const lookup = new Lookup(address);

          if (this.includeOnlyStates.length) {
            lookup.includeOnlyStates = this.includeOnlyStates;
          }

          return from(this.smartStreetClient.send(lookup)).pipe(
            map((response) => this.addresses$.next(response.result)),
            catchError((error) => of(error)),
          );
        }),
        tap(() => this.requestStatus$.next(RequestStatus.SUCCESS)),
        takeUntil(this.alive$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.alive$.next(null);
    this.alive$.complete();
  }
}
